import React from "react";
import { Link } from "gatsby";
import {
  Typography,
  Grid as MuiGrid,
  Link as MuiLink,
  makeStyles,
} from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import { spacing } from "@material-ui/system";

import Layout from "../components/layout/layout";
import { LINKS } from "../constants/links";

const Grid = styled(MuiGrid)(spacing);

const useStyles = makeStyles((theme) => ({
  link: {
    fontSize: 16,
    margin: theme.spacing(0.5, 0),
    padding: theme.spacing(0.5, 0),
    borderBottom: "1px solid transparent",
  },
  container:{
    marginBottom: 100,
  },
}));

const NotFound = ({ path }) => {
  const classes = useStyles();
  return (
    <Layout SeoProps={{ title: `Not Found` }} HeaderProps={{ path }}>
      <Grid container direction="column" alignItems="center" pt={20} pb={20} className={classes.container}>
        <Typography variant="h4">Page not found</Typography>
        <MuiLink
          component={Link}
          to={LINKS.root}
          className={classes.link}
          color="primary"
          underline="none"
        >
          Main page
        </MuiLink>
      </Grid>
    </Layout>
  );
};

export default NotFound;
